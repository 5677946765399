import React from 'react';
import './App.css';
import homeAlt from './assets/home-2.png';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-129388851-1');

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleInStockChange = this.handleInStockChange.bind(this);
  }

  handleFilterTextChange(e) {
    this.props.onFilterTextChange(e.target.value);
  }

  handleInStockChange(e) {
    this.props.onInStockChange(e.target.checked);
  }

  render() {
    return (
      <form>
        <input className="searchBar"
          type="text"
          placeholder="Search roles"
          value={this.props.filterText}
          onChange={this.handleFilterTextChange}
        />
      </form>
    );
  }
}

class JobPosition extends React.Component {

  render() {
    const { company, position } = this.props;

    if (position.active === true) {
      return (
        <div>
          <ReactGA.OutboundLink
            eventLabel={company}
            to={position.link}
            target="_blank" rel="noopener noreferrer">
            {position.name}
          </ReactGA.OutboundLink>
          <br />
        </div>
      );
    } else {
      return (
        <div>
            {position.name}
          <br />
        </div>)
    }
  }
}

class JobRow extends React.Component {
  render() {
    const { job, position } = this.props;
    const company = job.company;
    var addRow = false;
    const positionList = [];
    var detailString = '';

    if (position.active === this.props.active) {
      addRow = true;
      positionList.push(
        <JobPosition
          company={company}
          position={position}
          key={position.name}
          active={this.props.active} />
      );
    }

    if (addRow) {
      position.details.forEach(detail => {
        detailString += detail + ", "
      })
      detailString = detailString.substring(0, detailString.length - 2)

      return (
        <tr>
          <td>{company}</td>
          <td>{position.location}</td>
          <td>{positionList}</td>
          <td className="details-column">{detailString}</td>
        </tr>
      );
    } else {
      return <></>
    }
  }
}

class JobTable extends React.Component {
  render() {
    const filterText = this.props.filterText;
    const oldFilterText = this.props.oldFilterText;
    const rows = [];

    if (oldFilterText !== filterText) {
      this.props.handleOldFilterTextChange()
      return (
        <h6 className="errorText">Awks, there was no match <span role="img" aria-label="sad">😔</span></h6>
      );
    }

    this.props.jobs.forEach((job) => {
      job.positions.forEach((position) => {
        // if (job.company.toLowerCase().indexOf(filterText) === -1 && position.details.toLowerCase().indexOf(filterText) === -1 && job.location.toLowerCase().indexOf(filterText) === -1 && position.name.toLowerCase().indexOf(filterText) === -1) {
        //   return;
        // }
        if (position.details.indexOf(filterText) === -1 && filterText !== '') {
          return;
        }
        rows.push(
          <JobRow
            job={job}
            key={job.company}
            active={this.props.active}
            position={position} />
        );
      })
    });

    if (rows.length === 0) return (
      <h6 className="errorText">Awks, there was no match <span role="img" aria-label="sad">😔</span></h6>
    );

    return (
      <table className="u-full-width">
        <thead>
          <tr>
            <th>Company</th>
            <th>Location</th>
            <th>Position</th>
            <th className="details-column">Details</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

class FilterableJobTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      oldFilterText: '',
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleOldFilterTextChange = this.handleOldFilterTextChange.bind(this);
    this.detailSeason = this.detailSeason.bind(this);
  }

  handleFilterTextChange(filterText) {
    if (this.state.filterText === filterText) {
      document.getElementById(filterText).className = "detailButton";
      this.setState({
        filterText: ''
      });
    } else {
      if (this.state.filterText !== '') {
        document.getElementById(this.state.filterText).className = "detailButton";
      }
      this.setState({
        filterText: filterText
      });
      document.getElementById(filterText).className = "activeDetailButton";
    }
  }

  handleOldFilterTextChange() {
    this.setState({
      oldFilterText: this.state.filterText
    });
  }

  detailSeason(detail) {
    var includeSeason = false;
    var seasons = ["Summer", "Winter", "Spring", "Fall"]
    seasons.forEach(season => {
      if (detail.includes(season)) {
        includeSeason = true;
      }
    })
    return includeSeason
  }

  render() {
    const jobs = this.props.jobs;
    var hiring = [];
    var expiring = [];
    var detailsOption = []
    var newGrad = false;
    var sponsor = false;
    var months = { Summer: 3, Winter: 1, Spring: 2, Fall: 4 }


    jobs.forEach(job => {
      job.positions.forEach(position => {
        if (position.active) {
          hiring.push(job.company)
        } else {
          expiring.push(job.company)
        }

        position.details.forEach(detail => {
          if (position.active) {
            if (detail === "New Grad") {
              newGrad = true;
            } else if (detail === "Sponsor") {
              sponsor = true;
            } else if (detailsOption.indexOf(detail) === -1 && this.detailSeason(detail)) {
              detailsOption.push(detail)
            }
          }
        })
      })
    });

    detailsOption.sort((a, b) => {
      var aa = a.split(' '),
        bb = b.split(' ');
      return aa[1] - bb[1] || months[aa[0]] - months[bb[0]];
    });

    if (newGrad) {
      detailsOption.push("New Grad")
    }
    if (sponsor) {
      detailsOption.push("Sponsor")
    }

    var renderedOutput = this.props.active ? detailsOption.map(item => <div id={item} className="detailButton" onClick={(e) => this.handleFilterTextChange(item)}> {item} </div>) : null;

    return (
      <div id="jobs">
        <div className="container">
          <div className="row">
            <div className="six columns">
              <h5>{this.props.active ? hiring.length + " Open Positions" : expiring.length + " Closed Positions"}</h5>
              <h6>{this.props.active ? "Updated 11/18/21" : ""}</h6>
            </div>
            {/* <div className="six columns">
              {this.props.active ? <SearchBar
                filterText={this.state.filterText}
                onFilterTextChange={this.handleFilterTextChange}
              /> : <></>}
            </div> */}
            <div>
            </div>
          </div>
          <div className="buttonColumn">
            {renderedOutput}
          </div>
          <div className="twelve columns">
            <JobTable
              active={this.props.active}
              jobs={this.props.jobs}
              filterText={this.state.filterText}
              oldFilterText={this.state.oldFilterText}
              handleOldFilterTextChange={this.handleOldFilterTextChange}
            />
          </div>
        </div>
      </div>
    );
  }
}


class Intro extends React.Component {
  render() {
    return (
      <div id="intro">
        <div className="container">
          <div className="row">
            <div className="twelve columns center-box">
              <div className="six columns details">
                <ReactGA.OutboundLink
                  className="link"
                  eventLabel="Twitter"
                  target='_blank'
                  to="https://twitter.com/thetkkong">
                  Follow @thetkkong on Twitter
                  </ReactGA.OutboundLink>
                <h1>interns.design</h1>
                <h5>The easiest way to find paid design opportunities and resources to kickstart your career.</h5>
                <div className="row">
                  <ReactGA.OutboundLink
                    className="button"
                    eventLabel="Subscribe to Substack"
                    target='_blank'
                    to="https://interns.substack.com/welcome">
                    Subscribe to Newsletter
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    className="button-secondary"
                    eventLabel="Buy Me a Coffee"
                    target='_blank'
                    to="https://www.buymeacoffee.com/internsdesign">
                    Support Website
                  </ReactGA.OutboundLink>
                </div>
              </div>
              <div className="six columns">
                <a target="_blank" rel="noopener noreferrer" href="https://blush.design/collections/shiny-happy-people"><img src={homeAlt} alt="home illustration" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// class Footer extends React.Component {
//   render() {
//     return (
//       <div id="footer">
//         <div className="container">
//             <div className="row">
// {/* <div className="six columns u-blue">
//   <ReactGA.OutboundLink
//     eventLabel="Portfolio"
//     to="http://tkkong.com">
//     Made by TK
//   </ReactGA.OutboundLink>
// </div> */}
//               <div className="six columns footer-links u-grey">
//                 <a href="https://www.humaaans.com/" target="_blank" rel="noopener noreferrer">Illustration from Humaaans</a>
//               </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// class Banner extends React.Component {
//   render() {
//     return (
//       <div id="banner">
//         <div className="container">
//           <h5>New: Design Recruiting Database by interns.design 🚀</h5>
//           <p>Submit your information to be added to a portfolio database that will be shared with companies who are hiring design interns, full-time designers, or freelance designers. </p>
//           <ReactGA.OutboundLink
//             className="button-red"
//             eventLabel="Recruiting Airtable"
//             target='_blank'
//             to="https://airtable.com/shrolSLFxOckPDQbq">
//             Submit Your Portfolio
//           </ReactGA.OutboundLink>
//         </div>
//       </div>
//     );
//   }
// }

class Announcement extends React.Component {
  render() {
    return (
      <div id="announcement">
        <div className="container">
          <div className="row first">
            <div className="twelve columns">
              <p><span role="img" aria-label="alert">🚨</span> I apologize for the site being idle. I'm sourcing contributors to help out with interns.design v2. Stay tuned. <span role="img" aria-label="pray">🙏</span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="twelve columns">
              <p><span role="img" aria-label="briefcase">💼</span> Are you hiring a paid design intern, contract designer, or full-time designer? &nbsp;
                <ReactGA.OutboundLink
                  eventLabel="send email for job"
                  target='_blank'
                  to="mailto:tk@interns.design">
                  Email tk@interns.design
                </ReactGA.OutboundLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// class Modal extends React.Component {
//   render() {
//     return (
//       <div id="myModal" class="modal">
//         <div class="modal-content">
//           <span class="close">&times;</span>
//           <h1>Hello! <span role="img" aria-label="hand-wave">👋</span></h1>
//           <h5>Thanks for visiting! I'm TK, the maker of interns.design. Feel free to connect and send me any feedback!</h5>
//         </div>
//       </div>
//     );
//   }
// }

class App extends React.Component {
  render() {
    return (
      <div>
        {/* <Modal/> */}
        <Intro />
        {/* <Banner/> */}
        <Announcement />
        <FilterableJobTable jobs={this.props.jobs} active={true} />
        <FilterableJobTable jobs={this.props.jobs} active={false} />
        {/* <Footer /> */}
      </div>
    );
  }
}

export default App;
